<template>
  <div
    class="mb-0"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-8' : 'px-4'"
  >

    <!-- ==== DATA PENJUALAN Per Produk ==== -->
    <div class="my-5">
      <div class="text--secondary mb-3" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
        Data Rekap Penjualan Per Produk
      </div>
      <div class="mb-3 d-flex align-center flex-wrap">

        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`franchise/report/recap/transaction/product/export_excel?recap_job_id=${$route.params.id}`"
          :title="'Laporan Penjualan Per Produk'"
          ref="base-export-excel"
          v-on:onClickExport="responseExport"
        />
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'pdf'"
          :btnText="'Export PDF'"
          :btnIcon="'mdi-file-pdf-box'"
          :btnColor="'#f40f02'"
          :url="`franchise/report/recap/transaction/product/export_pdf?recap_job_id=${$route.params.id}`"
          :title="'Laporan Penjualan Per Produk'"
          ref="base-export-pdf"
          v-on:onClickExport="responseExport"
        />

        <v-btn
          depressed
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#aaa9ad"
          class="white--text text-capitalize"
          @click="$refs['base-table'].isFilterVisible = true"
        >
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
        </v-btn>
      </div>

      <!-- ==== FILTER STATUS ==== -->
      <div v-if="status.date || status.kategori || status.produk || status.store">
        Pencarian :
        <v-chip
          v-if="status.date"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('date')"
        >
          Tanggal: {{ filterdate.start | dateFull }} -
          {{ filterdate.end | dateFull }}
        </v-chip>
        <v-chip
          v-if="status.store"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('store')"
        >
          Outlet: {{ filternamestore }}
        </v-chip>
        <v-chip
          v-if="status.produk"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('product')"
        >
          Produk: {{ filternameproduk }}
        </v-chip>
        <!-- status.kategori = false;
            filterkategori = '';
            filterSave.kategori = ''; -->
        <v-chip
          v-if="status.kategori"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('category')"
        >
          Kategori: {{ filternamekategori }}
        </v-chip>
      </div>

      <!-- ==== LIST DATA SUMMARY PENJUALAN Per Produk ==== -->
      <v-expansion-panels flat accordion v-model="panel" focusable>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header
            class="title text--secondary"
            style="font-size:1rem !important;"
            >Rincian Penjualan Per Produk</v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="pt-5 mb-2"
            style=" box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:5px;"
          >
            <v-row>
              <v-col cols="6" sm="3" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''"
                v-for="(item, key) in total" :key="key">
                <v-card class="box-shadow border-radius pa-2">
                  <v-card-actions class="pt-2 px-0">
                    <div style="width:100%">
                      <v-card-title
                        class="font-weight-bold text--secondary px-0 py-0 pb-1"
                        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: 11px; line-height: 1; height: 30px' : ''"
                        :class="$vuetify.breakpoint.name === 'xs' ? '' : 'body-2'"
                      >
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#d31145"
                              v-if="item.note.title != ''"
                              v-on="on"
                              v-bind="attrs"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div class="d-flex flex-column">
                            <span class="font-weight-bold"><b>{{item.note.title}}</b></span>
                            <span>{{ item.note.content }}</span>
                          </div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text :class="$vuetify.breakpoint.name === 'xs' ? 'title text-second px-0' : 'headline text-second px-0'">
                        {{ item.value }}
                      </v-card-text>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ==== LIST DATA PENJUALAN Per Produk ==== -->
      <div v-show="process.run && list.length < 1">
        <v-skeleton-loader
          :loading="process.run"
          transition="scale-transition"
          :tile="false"
          type="table-tbody"
        >
        </v-skeleton-loader>
      </div>

      <!-- ==== BASE TABLE ==== -->
      <base-table
        v-on:onResetFilter="resetFilter"
        v-on:onRetrieveDataStart="responseDataStart"
        v-on:onRetrieveDataEnd="responseDataEnd"
        v-show="displayBaseTable"
        :isExternalFilterButton="true"
        :isFilterOnHeader="true"
        ref="base-table"
        dateType="date"
        dateFormat="DD MMMM YYYY"
        :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 450px)'"
        :filterHeader="filterHeader_salesProfit"
        itemKey="id"
        :single-select="singleSelect"
        :headers="headers"
        :retrieveDataURL="`franchise/report/recap/transaction/product?recap_job_id=${$route.params.id}`"
        filterType="laporan"
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ item.tanggal | dateFull }}
        </template>

        <template v-slot:[`item.sales_order_transaction_number`]="{ item }">
          {{ item.sales_order_transaction_number }}
        </template>

        <template v-slot:[`item.product_name`]="{ item }">
          {{ item.product_name }}
        </template>

        <template v-slot:[`item.product_code`]="{ item }">
          {{ item.product_code }}
        </template>

        <template v-slot:[`item.category_name`]="{ item }">
          {{ item.category_name }}
        </template>
        <template v-slot:[`item.total_product_qty`]="{ item }">
          {{ item.total_product_qty }}
          {{ item.sales_order_detail_log_product_unit }}
        </template>
        <template v-slot:[`item.total_product_selling_price`]="{ item }">
          {{ item.total_product_selling_price }}
        </template>
        <template v-slot:[`item.total_product_purchase_price`]="{ item }">
          {{ item.total_product_purchase_price }}
        </template>
        <template v-slot:[`item.total_bruto_price`]="{ item }">
          {{ item.total_bruto_price }}
        </template>
        <template v-slot:[`item.total_discount_nominal`]="{ item }">
          {{ item.total_discount_nominal }}
        </template>
        <template v-slot:[`item.total_nett_price`]="{ item }">
          {{ item.total_nett_price }}
        </template>

        <template v-slot:filterTitle>
          Filter Penjualan Per Produk
        </template>

        <template v-slot:customFilter>
          <VueCtkDateTimePicker
            v-model="filterdate"
            label="Pilih Tanggal"
            :range="true"
            noClearButton
            color="#d31145"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="todays"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' },
            ]"
          />
          <v-autocomplete
            item-text="store_name"
            item-value="store_id"
            :items="outlet"
            outlined
            clearable
            dense
            color="#d31145"
            class="mt-4"
            label="Pilih Outlet"
            v-model="filterstore"
          >
          </v-autocomplete>
          <v-autocomplete
            item-text="name"
            item-value="name"
            :items="produk"
            outlined
            clearable
            dense
            color="#d31145"
            label="Pilih Produk"
            v-model="filterproduk"
          >
          </v-autocomplete>
          <v-autocomplete
            item-text="title"
            item-value="title"
            :items="category"
            outlined
            clearable
            dense
            color="#d31145"
            label="Pilih Kategori"
            v-model="filterkategori"
          >
          </v-autocomplete>
        </template>
      </base-table>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-show="emptyData" />
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy, exportExcel } from "@/service/Axios";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import moment from "moment";
import Export from "@/components/Export";

export default {
  data() {
    return {
      panel: "",
      todays: new Date().toISOString().substr(0, 10),
      /**
       * DATA COMPONENT BASETABLE
       */
      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },

      headers: [
        {
          text: "Nama Produk",
          align: "left",
          width: 300,
          value: "product_name",
        },
        {
          text: "Nama Outlet",
          align: "left",
          width: 200,
          value: "store_name",
        },
        {
          text: "Kode Produk",
          align: "center",
          width: 150,
          value: "product_code",
        },
        {
          text: "Kategori Produk",
          align: "left",
          width: 200,
          value: "category_name",
        },
        {
          text: "Total Qty Produk Terjual",
          align: "center",
          width: 130,
          value: "total_product_qty",
        },
        {
          text: "Harga Jual (Rp)",
          align: "end",
          width: 200,
          value: "total_product_selling_price",
        },
        {
          text: "Harga Beli (Rp)",
          align: "end",
          width: 200,
          value: "total_product_purchase_price",
        },
        {
          text: "Bruto (Rp)",
          align: "end",
          width: 200,
          value: "total_bruto_price",
        },
        {
          text: "Diskon (Rp)",
          align: "end",
          width: 200,
          value: "total_discount_nominal",
        },
        {
          text: "Netto (Rp)",
          align: "end",
          width: 200,
          value: "total_nett_price",
        },
      ],
      /**
       * END DATA COMPONENT BASETABLE
       */

      filterstore: "",
      filternamestore: "",
      filterproduk: "",
      filternameproduk: "",
      filtercustomer: "",
      filternamecustomer: "",
      filterkategori: "",
      filternamekategori: "",
      filterdate: {},
      options: {},
      loading: false,
      hari: new Date().toISOString().substr(0, 10),

      process: {
        run: false,
        loading: false,
      },
      dialog: {
        filtered: false,
      },
      filterSave: {
        date: {},
        kategori: "",
        produk: "",
        customer: "",
      },
      status: {
        showTimeRangePanel: false,
        date: false,
        kategori: false,
        produk: false,
        store: false,
        customer: false,
      },
      outlet: [],
      cashier: [],
      list: [],
      produk: [],
      customer: [],
      category: [],
      total: {},
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  computed: {
    filterHeader_salesProfit() {
      return [
        {
          type: "custom",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
      ];
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {},
  created() {
    // this.initialize();
    this.fetchProduk();
    this.fetchCategory();
    this.fetchOutlet();
  },
  methods: {
    /**
     * EVENTS
     */

    resetFilter() {
      this.$refs["base-table"].params.start_date &&
        delete this.$refs["base-table"].params.start_date;
      this.$refs["base-table"].params.end_date &&
        delete this.$refs["base-table"].params.end_date;
      this.$refs["base-table"].params.product_name &&
        delete this.$refs["base-table"].params.product_name;
      this.$refs["base-table"].params.category_name &&
        delete this.$refs["base-table"].params.category_name;
      this.$refs["base-table"].params.store_name &&
        delete this.$refs["base-table"].params.store_name;




      this.status.date = false;
      this.filterdate = {};

      this.status.produk = false;
      this.filternameproduk = "";
      this.filterproduk = ""

      this.status.kategori = false;
      this.filternamekategori = "";
      this.filterkategori = ""

      this.status.store = false;
      this.filternamestore = "";
      this.filterstore = ""

      this.$refs["base-table"].retrieveData();
      
    },

    clearFilterChip(type) {
      switch (type) {
        case 'date':
          delete this.$refs["base-table"].params["filter[0][type]"];
          delete this.$refs["base-table"].params["filter[0][field]"];
          delete this.$refs["base-table"].params["filter[0][comparison]"];
          delete this.$refs["base-table"].params["filter[0][value]"];
          break;
        case 'product':
          delete this.$refs["base-table"].params["filter[1][type]"];
          delete this.$refs["base-table"].params["filter[1][field]"];
          delete this.$refs["base-table"].params["filter[1][comparison]"];
          delete this.$refs["base-table"].params["filter[1][value]"];
          break;
        case 'category':
          delete this.$refs["base-table"].params["filter[2][type]"];
          delete this.$refs["base-table"].params["filter[2][field]"];
          delete this.$refs["base-table"].params["filter[2][comparison]"];
          delete this.$refs["base-table"].params["filter[2][value]"];
          break;
        case 'store':
          delete this.$refs["base-table"].params["filter[3][type]"];
          delete this.$refs["base-table"].params["filter[3][field]"];
          delete this.$refs["base-table"].params["filter[3][comparison]"];
          delete this.$refs["base-table"].params["filter[3][value]"];
          break;
        default:
          break;
      }

      this.$refs["base-table"].retrieveData();
      this.resetFilter(type);
    },

    handleCloseChips(mode) {
      switch (mode) {
        case "date":
          this.resetFilter("date");
          this.status.date = false;
          this.filterdate = {};

          // this.$refs["base-table"].retrieveData();
          this.clearFilterChip(mode)
          break;
        case "product":
          this.status.produk = false;
          this.resetFilter("product");
          this.filternameproduk = "";
          this.filterproduk = ""
          // this.$refs["base-table"].retrieveData();
          this.clearFilterChip(mode)
          break;

        case "category":
          this.status.kategori = false;
          this.resetFilter("category");
          this.filternamekategori = "";
          this.filterkategori = ""
          // this.$refs["base-table"].retrieveData();
          this.clearFilterChip(mode)
          break;
        case "store":
          this.status.store = false;
          this.filternamestore = "";
          this.filterstore = "";
          // this.$refs["base-table"].retrieveData();
          this.resetFilter();
          this.clearFilterChip('store')
          break;
      }

      if (this.status.date ||
            this.status.kategori ||
            this.status.produk ||
            this.status.store ) {
        
      } else {
        if (this.$route.query.filter !== undefined) {
          this.$router.replace({ path: `${this.$route.path}` })
        }
      }
    },

    responseDataStart() {
      this.fetchProduk();
      this.displayBaseTable = false;
      this.emptyData = false;
      this.loading = true;
      this.process.run = true;

      /**
       * contains handler for filter params
       * date,product,category
       */

      //DATE
      if (Object.keys(this.filterdate).length != 0) {
        //get each item
        
        //assign params to item
        this.$refs["base-table"].params.start_date = this.filterdate.start
        this.$refs["base-table"].params.end_date = this.filterdate.end
        //show chips
        this.status.date = true;
      }

      //PRODUCT NAME

      // console.log(`produk:`, this.produk)
      if (this.filterproduk != "") {
        this.$refs["base-table"].params["filter[1][type]"] = "string";
        this.$refs["base-table"].params["filter[1][field]"] = "product_name";
        this.$refs["base-table"].params["filter[1][comparison]"] = "==";
        this.$refs["base-table"].params["filter[1][value]"] = this.filterproduk;

        //show chips
        this.status.produk = true;
        this.filternameproduk = this.filterproduk;
        
      }

      //CATEGORY
      if (this.filterkategori != "") {
        this.$refs["base-table"].params["filter[2][type]"] = "string";
        this.$refs["base-table"].params["filter[2][field]"] = "category_name";
        this.$refs["base-table"].params["filter[2][comparison]"] = "==";
        this.$refs["base-table"].params["filter[2][value]"] =  this.filterkategori;

        //show chips
        this.status.kategori = true;
        this.filternamekategori = this.filterkategori
      }

      // STORE
      if (this.filterstore != "") {
        this.$refs["base-table"].params["filter[2][type]"] = "string";
        this.$refs["base-table"].params["filter[2][field]"] = "store_id";
        this.$refs["base-table"].params["filter[2][comparison]"] = "==";
        this.$refs["base-table"].params["filter[2][value]"] = this.filterstore;

        this.outlet.forEach((item) => {
          this.status.store = true;
          if (item.store_id == this.filterstore) {
            this.filternamestore = item.store_name;
          }
        })
      }

    },

    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;
      // //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },

    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      this.fetchSummaryData()
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    async fetchSummaryData (store_id) {
      let start_date = this.filterdate.start ? this.filterdate.start : ''
      let end_date = this.filterdate.end ? this.filterdate.end: '' 

      const response  = await get(`franchise/report/recap/transaction/product/summary`, {
        params: {
          recap_job_id: this.$route.params.id,
          start_date,
          end_date,
          store_id: this.filterstore,
          product_name: this.filterproduk,
          category_name: this.filterkategori
        }
      })

      let res = response.data

      if (res.status == 200) {
        this.total = res.results.data
      }
    },
    /**
     * END EVENTS
     */

    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    async Export() {
      this.process.formExport = true;
      this.process.formExport = false;
    },
    
    
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    fetchProduk() {
      this.process.run = true;
      get(`franchise/report/recap/transaction/master/product`, {
        params: {
          recap_job_id: this.$route.params.id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.process.run = false;
            this.produk = res.results.data;
          }
          this.process.run = false;
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    fetchCategory() {
      this.process.run = true;
      get(`franchise/report/recap/transaction/master/product_category`, {
        params: {
          recap_job_id: this.$route.params.id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.process.run = false;
            this.category = res.results.data
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    fetchOutlet() {
      get(`franchise/report/recap_job/transaction/store`, {
        params: {
          id: this.$route.params.id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.outlet = res.results.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
  },
};
</script>
<style scoped>
.background-bank-cols {
  background-color: #cfd8dc;
  border: 3px solid #cfd8dc;
  border-radius: 10px;
}
</style>
